import { useQuery } from '@apollo/client';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AppContext } from '../AppContext';
import { ORDER, SORT_ON } from '../common/constants';
import { singleZero } from '../common/utils';
import { GET_SUBSCRIPTION_PLANS } from '../modules/plans/graphql/Queries';
import { GET_QUIZZES } from '../modules/quiz/graphql/Queries';
import {
  GET_USER,
  GET_USER_SUBSCRIPTION,
} from '../modules/subscription/graphql/Queries';

const SubscriptionContext = createContext(null);

const SubscriptionProvider = ({ children }) => {
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  const [featureText, setFeatureText] = useState({
    title: '',
    subText: '',
  });

  const [filter, setFilter] = useState({
    search: '',
    sortBy: ORDER[0]?.value,
    sortOn: SORT_ON[0]?.value,
  });

  const {
    state: { authToken },
  } = useContext(AppContext);

  const {
    data: quizList,
    loading: loadingQuizList,
    refetch: $refetch,
  } = useQuery(GET_QUIZZES, {
    skip: !authToken,
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const { data: plansData } = useQuery(GET_SUBSCRIPTION_PLANS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const { data: getUser } = useQuery(GET_USER, {
    skip: !authToken,
    fetchPolicy: 'network-only',
    onError() {},
  });

  const { data: getUserSubscription, refetch: subscriptionRefetch } = useQuery(
    GET_USER_SUBSCRIPTION,
    {
      variables: {
        data: {
          userId: getUser?.getUser?.id,
        },
      },
      skip: !getUser?.getUser?.id,
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const currentPlan = useMemo(
    () => getUserSubscription?.getUserSubscription?.subscriptionPlanName,
    [getUserSubscription],
  );

  const isFree = useMemo(() => (!currentPlan ? true : currentPlan === 'FREE'), [
    currentPlan,
  ]);

  const config = useMemo(() => {
    const { subscriptionFeatures } =
      getUserSubscription?.getUserSubscription?.userSubscription || {};
    let jsonData = {};
    if (subscriptionFeatures) {
      jsonData = JSON.parse(singleZero(subscriptionFeatures));
    }
    return {
      totalSession: jsonData?.noOfSessionAllowed,
      totalQuiz: jsonData?.allowedQuiz,
    };
  }, [getUserSubscription]);

  const refetch = useCallback(
    (arg) => {
      $refetch({
        ...arg,
      });
    },
    [$refetch],
  );

  const refetchSubscription = useCallback(() => {
    subscriptionRefetch({
      data: {
        userId: getUser?.getUser?.id,
      },
    });
  }, [subscriptionRefetch, getUser?.getUser?.id]);

  const totalQuiz = useMemo(() => quizList?.getQuizzes?.quizzes?.length, [
    quizList?.getQuizzes?.quizzes?.length,
  ]);

  const value = useMemo(
    () => ({
      setOpenUpgradeModal,
      openUpgradeModal,
      openLoader,
      setOpenLoader,
      featureText,
      setFeatureText,
      plansData,
      currentPlan,
      getUserSubscription,
      refetchSubscription,
      getUser,
      isFree,
      config,
      totalQuiz,
      // quiz list api values
      refetch,
      quizList,
      loadingQuizList,
      setFilter,
      filter,
    }),
    [
      setOpenUpgradeModal,
      openUpgradeModal,
      openLoader,
      setOpenLoader,
      featureText,
      setFeatureText,
      plansData,
      currentPlan,
      getUserSubscription,
      refetchSubscription,
      getUser,
      isFree,
      config,
      totalQuiz,
      // quiz list api values
      refetch,
      quizList,
      loadingQuizList,
      setFilter,
      filter,
    ],
  );

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const ctx = useContext(SubscriptionContext);
  if (!ctx)
    throw new Error('useSubscription must be used inside SubscriptionProvider');

  return ctx;
};

export default SubscriptionProvider;
