import {
  DeleteFilled,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import { QUESTION_ID, QUESTION_TYPE } from '../../../common/constants';
import RouterPrompt from '../../../components/RouterPrompt';

const { Text } = Typography;

const DeleteQuestionComponent = ({ remove }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <RouterPrompt
        isPrompt={open}
        handleOK={() => {
          remove();
          setOpen(false);
        }}
        handleCancel={() => setOpen(false)}
        title="Delete question"
        description="Are you sure to delete this question!"
      />
      <div className="d-flex">
        <Space size="small" wrap>
          <Button
            type="primary"
            onClick={() => setOpen(true)}
            icon={<DeleteFilled />}
          />
        </Space>
      </div>
    </>
  );
};

const QuizQuestionForm = ({
  watchForm,
  addNewQuiz = true,
  isFormDisabled = false,
  form,
}) => {
  const [showError, setShowError] = useState({});
  const validateOptions = (_, value, idx) => {
    const options = watchForm?.[idx]?.optionsValues;
    const isChecked = options?.some((option) => option.correctAnswer);
    if (isChecked) {
      setShowError((prev) => ({ ...prev, [idx]: false }));
      return Promise.resolve();
    }
    setShowError((prev) => ({ ...prev, [idx]: true }));
    return Promise.reject(new Error(''));
  };

  const showMoreInfo = (key) =>
    watchForm?.[key]?.title?.length > 200 && (
      <span className="pointer ml-6">
        <Tooltip
          overlayClassName="option-tooltip-value"
          title={watchForm?.[key]?.title}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </span>
    );

  return (
    <>
      <Col span={24}>
        <Form.List name="questionData">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Card
                  key={key}
                  className="mb-16"
                  title={
                    <>
                      <div className="d-flex justify-between">
                        <h3 type="secondary" className="bold m-0">
                          Question {idx + 1}
                        </h3>
                        {!isFormDisabled && fields?.length > 1 && (
                          <DeleteQuestionComponent
                            remove={() => remove(name)}
                          />
                        )}
                      </div>
                    </>
                  }
                >
                  <Row gutter={[16, 8]}>
                    <Col span={18}>
                      <div className="d-flex w-full align-center gap-6">
                        <Form.Item
                          className="flex-1"
                          {...restField}
                          label="Question Title"
                          name={[name, 'title']}
                          rules={[
                            { required: true, message: 'Title required!' },
                          ]}
                        >
                          <Input placeholder="Title" className="w-full" />
                        </Form.Item>
                        <span>{showMoreInfo(key)}</span>
                      </div>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        label="Question Type"
                        name={[name, 'type']}
                        rules={[{ required: true, message: 'Type required!' }]}
                      >
                        <Select placeholder="Select Question Type">
                          {map(QUESTION_TYPE, (list) => (
                            <Select.Option
                              key={list?.value}
                              value={list?.value}
                            >
                              {list?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} className="options">
                      <Form.List
                        name={[name, 'optionsValues']}
                        initialValue={[{ title: '', id: '' }]}
                      >
                        {(innerFields) => (
                          <Row>
                            {innerFields.map(
                              (
                                {
                                  key: keyInner,
                                  name: nameInner,
                                  ...restSubField
                                },
                                index,
                              ) => (
                                <Col
                                  span={12}
                                  className="d-flex mb-24 align-center option-input-field"
                                  key={keyInner}
                                >
                                  <Text>{QUESTION_ID[index]?.value}.</Text>
                                  <Form.Item
                                    {...restSubField}
                                    name={[nameInner, 'title']}
                                    rules={[
                                      {
                                        message: 'Option required!',
                                        required: [
                                          QUESTION_ID[0]?.value,
                                          QUESTION_ID[1]?.value,
                                        ].includes(QUESTION_ID[index]?.value),
                                      },
                                    ]}
                                    className="ml-8 m-0 option-filed"
                                  >
                                    <Input
                                      placeholder={`Option ${QUESTION_ID[index]?.value}`}
                                      onChange={(e) => {
                                        if (!e.target.value) {
                                          form.setFieldValue(
                                            [
                                              'questionData',
                                              name,
                                              'optionsValues',
                                              nameInner,
                                              'correctAnswer',
                                            ],
                                            false,
                                          );
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restSubField}
                                    name={[nameInner, 'correctAnswer']}
                                    valuePropName="checked"
                                    className="ml-8 m-0"
                                    rules={[
                                      {
                                        validator: (_, value) =>
                                          validateOptions(_, value, idx),
                                      },
                                    ]}
                                  >
                                    <Checkbox
                                      className={`${
                                        showError[idx] &&
                                        !!watchForm?.[idx]?.optionsValues?.[
                                          index
                                        ]?.title
                                          ? 'border-red'
                                          : ''
                                      }`}
                                      onChange={(e) => {
                                        if (e?.target?.checked) {
                                          [...Array(4).keys()].forEach(
                                            (element) => {
                                              if (element !== nameInner)
                                                form.setFieldValue(
                                                  [
                                                    'questionData',
                                                    name,
                                                    'optionsValues',
                                                    element,
                                                    'correctAnswer',
                                                  ],
                                                  false,
                                                );
                                            },
                                          );
                                        }
                                      }}
                                      disabled={
                                        isFormDisabled ||
                                        !watchForm?.[idx]?.optionsValues?.[
                                          index
                                        ]?.title
                                      }
                                    />
                                  </Form.Item>
                                  {watchForm?.[idx]?.optionsValues?.[index]
                                    ?.title?.length > 70 && (
                                    <span className="pointer ml-6">
                                      <Tooltip
                                        overlayClassName="option-tooltip-value"
                                        title={
                                          watchForm?.[idx]?.optionsValues?.[
                                            index
                                          ]?.title
                                        }
                                      >
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                  )}
                                </Col>
                              ),
                            )}
                          </Row>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Card>
              ))}
              {addNewQuiz && (
                <Form.Item>
                  <Button
                    type="default"
                    onClick={() =>
                      add({
                        title: '',
                        type: QUESTION_TYPE?.[0].value,
                        optionsValues: [
                          { title: '', correctAnswer: false },
                          { title: '', correctAnswer: false },
                          { title: '', correctAnswer: false },
                          { title: '', correctAnswer: false },
                        ],
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add New Question
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </Col>
    </>
  );
};

export default QuizQuestionForm;
