import React from 'react';
import LoaderComponent from '../../components/LoaderComponent';
import { useSubscription } from '../../context/SubscriptionProvider';
import QuizForm from './components/QuizForm';

const AddQuiz = () => {
  const { totalQuiz, isFree, config } = useSubscription();

  return (
    <div>
      <LoaderComponent
        spinning={isFree && totalQuiz && totalQuiz === config?.totalQuiz + 1} // default quiz excluded
      >
        <QuizForm />
      </LoaderComponent>
    </div>
  );
};

export default AddQuiz;
