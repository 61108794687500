import {
  CrownFilled,
  DollarOutlined,
  DownOutlined,
  LoginOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { useSubscription } from '../../../context/SubscriptionProvider';
import withFeatureRestriction from '../../../modules/plans/components/withFeatureRestriction';

function OnBoardingLink({ onClick }) {
  const { isFree, setFeatureText } = useSubscription();

  return isFree ? (
    <div
      onClick={() => {
        setFeatureText((prev) => ({
          ...prev,
          title: 'Upgrade your plan to unlock this feature!',
          subText:
            'Enhance your brand by upgrading your plan to easily change your company name and profile icon.',
        }));
        onClick();
      }}
    >
      My Account <CrownFilled className="crown-icon" />
    </div>
  ) : (
    <Link to={ROUTES?.ONBOARDING}>My Account</Link>
  );
}

const ConditionalOnBoardingLink = withFeatureRestriction(OnBoardingLink);

const UserProfile = () => {
  const { pathname } = useLocation();
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const userData = getCurrentUser();

  const items = [
    {
      key: 'onboarding',
      label: <ConditionalOnBoardingLink />,
      icon: <UserOutlined />,
    },
    {
      key: 'subscription',
      label: <Link to={ROUTES?.SUBSCRIPTION}>Subscription</Link>,
      icon: <DollarOutlined />,
    },
    {
      key: 'logout',
      label: userData?.isConfigSet ? (
        <Link to={ROUTES?.LOGOUT}>Logout</Link>
      ) : (
        <span
          className="display-block"
          onClick={() => {
            dispatch({ type: 'LOGOUT' });
            // eslint-disable-next-line no-undef
            window.location = ROUTES.LOGIN;
          }}
        >
          Logout
        </span>
      ),
      icon: <LoginOutlined />,
    },
  ];

  const avatarText = userData?.companyName?.[0] || userData?.email?.[0];

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
      className="user-profile"
      // eslint-disable-next-line no-undef
      getPopupContainer={() => document?.getElementById('header')}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        {avatarText && (
          <Avatar className="header-avatar" alt="Avatar">
            {capitalize(avatarText)}
          </Avatar>
        )}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
