import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_CONFIG = gql`
  mutation UpdateConfig($data: UpdateConfigInputData!) {
    updateConfig(data: $data) {
      message
    }
  }
`;
